<template>
  <div class="account-page">
    <div v-if="$system.user.isAuthenticated" class="container">
      <!--Page title-->
      <Title :title="title" />
      <!--Account information-->
      <div v-if="!loading">
        <div class="row">
          <div class="col-12">
            <b-tabs content-class="mt-3" fill>
              <!-- Account detail-->
              <b-tab title="Account detail" active>
                <div class="row">
                  <!--Account Name-->
                  <div class="col-6 col-lg-4">
                    <div class="account__item">
                      <div class="account__item__title">Name:</div>
                      <div class="account__item__text">
                        <template v-if="account.Name">
                          {{ account.Name }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                  <!--Account DBA-->
                  <div class="col-6 col-lg-4">
                    <div class="account__item">
                      <div class="account__item__title">DBA:</div>
                      <div class="account__item__text">
                        <template v-if="account.Name">
                          {{ account.DBA }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                  <!--Account Phone-->
                  <div class="col-6 col-lg-4">
                    <div class="account__item">
                      <div class="account__item__title">Phone:</div>
                      <div class="account__item__text">
                        <template v-if="account.Phone">
                          <a :href="'tel:' + account.Phone">
                            {{ account.Phone }}
                          </a>
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                  <!--Account Email-->
                  <div class="col-6 col-lg-4">
                    <div class="account__item">
                      <div class="account__item__title">Email:</div>
                      <div class="account__item__text">
                        <template v-if="account.Email">
                          <a :href="'mailto:' + account.Email">
                            {{ account.Email }}
                          </a>
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                  <!--Billing Address-->
                  <div v-if="account.BillingAddress" class="col-12 mt-3 mb-2">
                    <b>Billing Address:</b>
                  </div>
                  <!--Billing Address Street-->
                  <div class="col-6 col-lg-4">
                    <div class="account__item">
                      <div class="account__item__title">Street:</div>
                      <div class="account__item__text">
                        <template v-if="account.BillingAddress.Street">
                          {{ account.BillingAddress.Street }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                  <!--Billing Address State-->
                  <div class="col-6 col-lg-4">
                    <div class="account__item">
                      <div class="account__item__title">State:</div>
                      <div class="account__item__text">
                        <template v-if="account.BillingAddress.State">
                          {{ account.BillingAddress.State }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                  <!--Billing Address Postal Code-->
                  <div class="col-6 col-lg-4">
                    <div class="account__item">
                      <div class="account__item__title">Postal Code:</div>
                      <div class="account__item__text">
                        <template v-if="account.BillingAddress.PostalCode">
                          {{ account.BillingAddress.PostalCode }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                  <!--Billing Address Country-->
                  <div class="col-6 col-lg-4">
                    <div class="account__item">
                      <div class="account__item__title">Country:</div>
                      <div class="account__item__text">
                        <template v-if="account.BillingAddress.Country">
                          {{ account.BillingAddress.Country }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                  <!--Billing Address City-->
                  <div class="col-6 col-lg-4">
                    <div class="account__item">
                      <div class="account__item__title">City:</div>
                      <div class="account__item__text">
                        <template v-if="account.BillingAddress.City">
                          {{ account.BillingAddress.City }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <!--Repayments-->
              <b-tab title="Repayments">
                <!--Search Repayments-->
                <div class="row">
                  <b-input-group size="sm" class="mb-3 col-12 col-lg-3">
                    <b-form-input
                      id="filter-input"
                      v-model="filterRepayments"
                      type="search"
                      placeholder="Enter search text"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        :disabled="!filterRepayments"
                        @click="filterRepayments = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <div class="col-2 col-lg-3 mb-3">
                    <b-form-group
                      label="Per page"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="perPageRepayments"
                        :options="pageOptionsRepayments"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-10 col-lg-1 offset-lg-5 mb-3">
                    <div class="flex-end">
                      <b-icon-arrow-counterclockwise
                        @click="getAccount"
                        class="btn__refresh"
                        v-b-tooltip.hover
                        title="Refresh"
                      />
                    </div>
                  </div>
                </div>
                <!--Repayments Table-->
                <div class="row">
                  <div v-if="account.Repayments" class="col-12 m-0">
                    <b-table
                      v-if="account.Repayments.length"
                      class="table"
                      hover
                      striped
                      :items="account.Repayments"
                      :fields="fieldsRepayments"
                      :filter="filterRepayments"
                      :filter-included-fields="filterOnRepayments"
                      responsive
                      :current-page="currentPageRepayments"
                      :per-page="perPageRepayments"
                    >
                      <!--Repayment Router Link-->
                      <template #cell(Name)="row">
                        <div>
                          <router-link
                            :to="'/repayments/' + row.item.Id"
                            v-b-tooltip.hover
                            title="Show repayment"
                            class="active-link-table"
                          >
                            {{ row.item.Name }}
                          </router-link>
                        </div>
                      </template>
                    </b-table>
                    <div v-else class="flex-center col-12 m-0">
                      <p class="text-center p-5">No records found.</p>
                    </div>
                  </div>
                  <!--Repayment Table Pagination-->
                  <div class="col-12 flex-center">
                    <b-pagination
                      v-model="currentPageRepayments"
                      :total-rows="totalRowsRepayments"
                      :per-page="perPageRepayments"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </div>
                </div>
              </b-tab>
              <!--Disbursements-->
              <b-tab title="Disbursements">
                <!--Search Disbursements-->
                <div class="row">
                  <b-input-group size="sm" class="mb-3 col-12 col-lg-3">
                    <b-form-input
                      id="filter-input"
                      v-model="filterDisbursements"
                      type="search"
                      placeholder="Enter search text"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        :disabled="!filterDisbursements"
                        @click="filterDisbursements = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <div class="col-2 col-lg-3 mb-3">
                    <b-form-group
                      label="Per page"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="perPageDisbursements"
                        :options="pageOptionsDisbursements"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-10 col-lg-1 offset-lg-5 mb-3">
                    <div class="flex-end">
                      <b-icon-arrow-counterclockwise
                        @click="getAccount"
                        class="btn__refresh"
                        v-b-tooltip.hover
                        title="Refresh"
                      />
                    </div>
                  </div>
                </div>
                <!--Disbursements Table-->
                <div class="row">
                  <div v-if="account.Disbursements" class="col-12 m-0">
                    <b-table
                      v-if="account.Disbursements.length"
                      class="table"
                      hover
                      striped
                      :items="account.Disbursements"
                      :fields="fieldsDisbursements"
                      :filter="filterDisbursements"
                      :filter-included-fields="filterOnDisbursements"
                      responsive
                      :current-page="currentPageDisbursements"
                      :per-page="perPageDisbursements"
                    ></b-table>
                    <div v-else class="flex-center col-12 m-0">
                      <p class="text-center p-5">No records found.</p>
                    </div>
                  </div>
                  <!--Repayment Table Pagination-->
                  <div class="col-12 flex-center">
                    <b-pagination
                      v-model="currentPageDisbursements"
                      :total-rows="totalRowsDisbursements"
                      :per-page="perPageDisbursements"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
      <div v-else class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
    <div v-else class="container">
      <div class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import './AccountPage.scss'
import AccountsService from '@/services/account/account.service'
import Title from '@/components/main-layout/title/Title.vue'
export default {
  name: 'AccountPage',
  components: {
    Title,
  },

  metaInfo: {
    title: 'Account inforamtion',
    titleTemplate: '%s | Merchant Portal | Nexi ',
  },
  data() {
    return {
      loading: false,
      title: 'Account information',
      account: {},
      fieldsDisbursements: [
        {
          key: 'Product',
          label: 'Product',
          sortable: true,
        },
        {
          key: 'TotalAmount',
          label: 'Total Amount',
          sortable: true,
        },
        {
          key: 'TotalDistributions',
          label: 'Total Distributions',
          sortable: true,
        },
        {
          key: 'NextDistributionDate',
          label: 'Next Distribution Date',
          sortable: false,
        },
        {
          key: 'NextDistributionAmount',
          label: 'Next Distribution Amount',
          sortable: true,
        },
        {
          key: 'RemainingBalance',
          label: 'Remaining Balance',
          sortable: true,
        },
        {
          key: 'PaidBalance',
          label: 'Paid Balance',
          sortable: true,
        },
      ],
      fieldsRepayments: [
        {
          key: 'Name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'Type',
          label: 'Type',
          sortable: true,
        },
        {
          key: 'PercentPaid',
          label: 'Percent Paid',
          sortable: true,
        },
        {
          key: 'StartDate',
          label: 'Start Date',
          sortable: false,
        },
        {
          key: 'Status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'FundingAmount',
          label: 'Funding Amount',
          sortable: true,
        },
        {
          key: 'PaybackAmount',
          label: 'Payback Amount',
          sortable: true,
        },
        {
          key: 'FactorRate',
          label: 'FR',
          sortable: true,
        },
        {
          key: 'ClearedPaid',
          label: 'Cleared Paid',
          sortable: true,
        },
        {
          key: 'PendingBalance',
          label: 'Pending Balance',
          sortable: true,
        },
        {
          key: 'ClearedBalance',
          label: 'Cleared Balance',
          sortable: true,
        },
        {
          key: 'CVE',
          label: 'CVE',
          sortable: true,
        },
      ],
      filterRepayments: null,
      filterOnRepayments: [],
      totalRowsRepayments: 1,
      currentPageRepayments: 1,
      perPageRepayments: 20,
      pageOptionsRepayments: [20, 40, 60, { value: 1000, text: 'Show all' }],
      filterDisbursements: null,
      filterOnDisbursements: [],
      totalRowsDisbursements: 1,
      currentPageDisbursements: 1,
      perPageDisbursements: 20,
      pageOptionsDisbursements: [20, 40, 60, { value: 1000, text: 'Show all' }],
    }
  },
  computed: {
    auth() {
      return this.$system.user.isAuthenticated
    },
  },
  watch: {
    auth() {
      this.getAccount()
    },
  },
  created() {
    if (this.$system.user.isAuthenticated) {
      this.getAccount()
    } else this.$system.firstLoading()
  },
  methods: {
    async getAccount() {
      try {
        this.loading = true
        const response = await AccountsService.getAccount(
          this.$system.user,
          this.$route.params.id,
        )
        if (
          response &&
          response.status == 'success' &&
          response.body &&
          response.body.data
        ) {
          this.account = response.body.data
          this.loading = false
          this.totalRowsRepayments = this.account.Repayments.length
          this.totalRowsDisbursements = this.account.Disbursements.length
        } else {
          this.$router.push('/accounts')
          this.loading = false
        }
      } catch (err) {
        this.$router.push('/accounts')
        this.loading = false
      }
    },
  },
}
</script>
