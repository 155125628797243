import Axios from '../axios-instance/axios.service';

const API_URL =
  process.env.NODE_ENV !== 'development' ? `${window.location.origin}/api/` : 'http://localhost:5000/api/';

class AccountService {

  async getAccount(user, id) {
    let req = {
      ...user,
      accountId: id
    }
    const { data } = await Axios.post(`${API_URL}account`, req);
    return data;
  }
}

export default new AccountService();